//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  contactsStore,
  teamsStore,
  uiStore,
  uiSettingsStore,
  rootStore,
} from '@/store'
import { format } from 'quasar'
import { mapGetters } from 'vuex'

const { capitalize } = format

export default {
  name: 'TasksMenu',

  data () {
    return {
      taskNotifications: false,
    }
  },

  computed: {
    ...mapGetters([
      'getUserId',
      'profile',
    ]),

    taskNotificationsFromStore () {
      return this.profile.taskNotificationsEnabled
    },

    options () {
      const options = []

      if (this.profile.changeableFields.includes('task_short_view')) {
        const label = this.$t('chatlist.appearanceCompact')
        const value = teamsStore.getters.currentTeam.me.taskShortView
        const action = value => teamsStore.actions.setRosterListType({
          chatType: 'task',
          isShort: value,
        })
        options.push({ label, value, action })
      }

      if (this.profile.changeableFields.includes('task_notifications_enabled')) {
        options.push({
          label: capitalize(this.$t('glossary.notifications')),
          caption: this.$t('profiles.notifications.captionDisabled'),
          disable: !rootStore.state.isNotificationsAllowed,
          value: this.taskNotifications,
          action: this.onChangeTaskNotifications,
        })
      }

      return options
    },

    experimentalOptions () {
      const tasksTabsDesign = {
        label: 'Группировка табов',
        value: uiSettingsStore.getters.uiSettings.experimentalTasksTabsDesign ?? false,
        action: value => uiSettingsStore.actions.updateExperimentalTasksTabDesign(value),
      }

      return [tasksTabsDesign]
    },
  },

  watch: {
    taskNotificationsFromStore: {
      immediate: true,
      handler (value) {
        this.taskNotifications = value
      },
    },
  },

  created () {
    this.isDev = window.FEATURES.is_testing
  },

  methods: {
    showExportModal () {
      uiStore.actions.showModal({
        instance: 'ExportModal',
      })
    },
    showImportModal () {
      uiStore.actions.showModal({
        instance: 'ImportTasks',
      })
    },
    async onChangeTaskNotifications (value) {
      this.taskNotifications = value
      try {
        await contactsStore.actions.editContact({
          jid: this.getUserId,
          params: { task_notifications_enabled: value },
        })
      } catch (e) {
        // TODO: refactor this
        setTimeout(() => {
          this.taskNotifications = this.taskNotificationsFromStore
        }, 300)
        throw e
      }
    },
  },
}
